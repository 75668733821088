/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { message } from 'antd';
import { DownloadFileType } from 'app/constants';
import {
  DownloadTask,
  DownloadTaskState,
} from 'app/pages/MainPage/slice/types';
import { ExecuteToken } from 'app/pages/SharePage/slice/types';
import { ChartDataRequest } from 'app/types/ChartDataRequest';
import ChartDataSetDTO from 'app/types/ChartDataSet';
import { ChartDTO } from 'app/types/ChartDTO';
import {
  filterSqlOperatorName,
  transformToViewConfig,
} from 'app/utils/internalChartHelper';
import { saveAs } from 'file-saver';
import { request, request2, requestWithHeader } from 'utils/request';
import { errorHandle } from 'utils/utils';
import {RootState} from "../../types";
import {configureAppStore} from "../../redux/configureStore";
import {Root} from "postcss";
import {useSelector} from "react-redux";
import {selectConfigInfo} from "../slice/selectors";
import {ConfigInfo, OssConfigInfo} from "../slice/types";
import * as oss from 'ali-oss'
import {StorageKeys} from "../../globalConstants";
import { convertToChartDto } from './ChartDtoHelper';
import OSS from "ali-oss";

export const getDistinctFields = async (
  viewId: string,
  columns: string[],
  view: ChartDTO['view'] | undefined,
  executeToken: ExecuteToken | undefined,
) => {
  const viewConfigs = transformToViewConfig(view?.config);
  const requestParams: ChartDataRequest = {
    aggregators: [],
    filters: [],
    groups: [],
    columns: [...new Set(columns)],
    pageInfo: {
      pageNo: 1,
      pageSize: 99999999,
      total: 99999999,
    },
    orders: [],
    keywords: ['DISTINCT'],
    viewId,
    ...viewConfigs,
  };
  if (executeToken) {
    // console.log("executeToken4:" + JSON.stringify(executeToken));
    const { data } = await request2<ChartDataSetDTO>({
      method: 'POST',
      url: `shares/execute`,
      params: {
        executeToken: executeToken?.authorizedToken,
      },
      data: requestParams,
    });
    return filterSqlOperatorName(requestParams, data);
  } else {
    // console.log("executeToken4:" + JSON.stringify(requestParams));
    const { data } = await request2<ChartDataSetDTO>({
      method: 'POST',
      url: `data-provider/execute`,
      data: requestParams,
    });
    return filterSqlOperatorName(requestParams, data);
  }
};

export const syncDownloadData =
  (params: {
    downloadParams: ChartDataRequest[];
    fileName: string;
    downloadType: DownloadFileType;
    imageWidth?: number;
    resolve: () => void;
    downloadWithStyle?: boolean;
    withParam?: boolean;
    shareBoard?: boolean;
    executeToken?: Record<string, ExecuteToken>;
  }) =>
    async () => {
      const { downloadParams, fileName, resolve, downloadType, imageWidth, downloadWithStyle, withParam, shareBoard, executeToken } =
        params;
      // const clientId = localStorage.getItem('SHARE_CLIENT_ID');
      const urlParams = document.location.href;
      let clientId;
      if (urlParams.indexOf('&user=') > -1) {
        clientId = localStorage.getItem(StorageKeys.ShareSystemClientId);
      } else {
        clientId = localStorage.getItem(StorageKeys.ShareClientId);
      }
      let url = shareBoard? 'shares/files/sync/download?clientId=' + clientId: 'download/files/sync/download';
      const [data, headers] = await requestWithHeader({
        url: url,
        method: 'POST',
        data: {
          downloadParams: downloadParams,
          fileName: fileName,
          downloadType,
          imageWidth,
          downloadWithStyle,
          withParam,
          executeToken: shareBoard ? executeToken : null
        },
        responseType: 'blob',
      }).catch((error) => {
        const reader = new FileReader();
        reader.readAsText(error.response.data, 'utf-8');
        reader.onload = function (e) {
          errorHandle(JSON.parse(reader.result as string));
        }
      });
      dealFileSave(data, headers);
  };


export const exportReportData =
  (params: {
    reportId: string;
    before: () => void;
    resolve: () => void;
  }) =>
    async () => {
      const { reportId, before, resolve } =
        params;
      before();
      let url = 'report/export/' + reportId;
      const [data, headers] = await requestWithHeader({
        url: url,
        method: 'POST',
        responseType: 'blob',
      }).catch((error) => {
        const reader = new FileReader();
        reader.readAsText(error.response.data, 'utf-8');
        reader.onload = function (e) {
          errorHandle(JSON.parse(reader.result as string));
        }
        resolve();
      });
      resolve();
      dealFileSave(data, headers);
    };

export const makeDownloadDataTask =
  (params: {
    downloadParams: ChartDataRequest[];
    fileName: string;
    downloadType: DownloadFileType;
    imageWidth?: number;
    resolve: () => void;
    downloadWithStyle?: boolean;
  }) =>
  async () => {
    const { downloadParams, fileName, resolve, downloadType, imageWidth, downloadWithStyle } =
      params;

    const res = await request2<{}>({
      url: `download/submit/task`,
      method: 'POST',
      data: {
        downloadParams: downloadParams,
        fileName: fileName,
        downloadType,
        imageWidth,
        downloadWithStyle,
      },
    }, {}, {onRejected: error => {
      message.error(error.response.data.message);
      }});
    if (res?.success) {
      message.success('下载任务创建成功');
    }
    resolve();
  };
// TODO
export const makeShareDownloadDataTask =
  (params: {
    resolve: () => void;
    clientId: string;
    fileName: string;
    downloadParams: ChartDataRequest[];
    shareToken: string;
    executeToken?: Record<string, ExecuteToken>;
    password?: string | null;
  }) =>
  async () => {
    const {
      downloadParams,
      fileName,
      resolve,
      executeToken,
      clientId,
      password,
      shareToken,
    } = params;
    const { success } = await request<{}>({
      url: `shares/download`,
      method: 'POST',
      data: {
        downloadParams,
        fileName: fileName,
        executeToken,
        shareToken,
      },
      params: {
        password,
        clientId,
      },
    });
    if (success) {
      message.success('下载任务创建成功');
    }
    resolve();
  };

export async function checkComputedFieldAsync(sourceId, expression) {
  const _removeSquareBrackets = expression => {
    if (!expression) {
      return '';
    }
    return expression.replaceAll('[', '').replaceAll(']', '');
  };
  const response = await request2<boolean>({
    method: 'POST',
    url: `data-provider/function/validate`,
    params: {
      sourceId,
      snippet: _removeSquareBrackets(expression),
    },
  });
  return !!response?.data;
}

export async function fetchAvailableSourceFunctionsAsync(sourceId) {
  const response = await request<string[]>({
    method: 'POST',
    url: `data-provider/function/support/${sourceId}`,
  });
  return response?.data;
}

export async function generateShareLinkAsync({
  expiryDate,
  vizId,
  vizType,
  authenticationMode,
  roles,
  users,
  rowPermissionBy,
  linkType,
}) {
  const response = await request2<{
    data: any;
    errCode: number;
    message: string;
    success: boolean;
  }>({
    method: 'POST',
    url: `shares`,
    data: {
      expiryDate: expiryDate,
      authenticationMode,
      roles,
      users,
      rowPermissionBy,
      linkType,
      vizId: vizId,
      vizType,
    },
  });
  return response?.data;
}

export const dealFileSave = (data, headers) => {
  const fileNames = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g.exec(
    headers?.['content-disposition'] || '',
  );
  const encodeFileName = decodeURIComponent(fileNames?.[1] || '');
  const blob = new Blob([data], { type: '**application/octet-stream**' });
  saveAs(blob, String(encodeFileName?.replaceAll('"', '')) || 'unknown.xlsx');
};

export async function downloadFile(id) {
  let promiseList = [getSysConfig(), getFileInfo(id)];
  Promise.all(promiseList).then(async (value) => {
    const config = value[0] as ConfigInfo[];
    const {name, path} = value[1] as { name: string, path: string };
    // window.location.href = path;
    const blob = await getBlob(path);
    saveAsFile(blob, name);
  })

  // const [data, headers] = (await requestWithHeader({
  //   url: `download/files/${id}`,
  //   method: 'GET',
  //   responseType: 'blob',
  // })) as any;
  // dealFileSave(data, headers);
}

export async function fetchPluginChart(path) {
  const result = await request2(path, {
    baseURL: '/',
    headers: { Accept: 'application/javascript' },
  }).catch(error => {
    console.error(error);
  });
  return result || '';
}

export async function getChartPluginPaths() {
  const response = await request2<string[]>({
    method: 'GET',
    url: `plugins/custom/charts`,
  });
  return response?.data || [];
}

export async function loadShareTask(params) {
  try {
    const { data } = await request2<DownloadTask[]>({
      url: `/shares/download/task`,
      method: 'GET',
      params,
    });
    const isNeedStopPolling = !(data || []).some(
      v => v.status === DownloadTaskState.CREATED,
    );
    return {
      isNeedStopPolling,
      data: data || [],
    };
  } catch (error) {
    errorHandle(error);
    throw error;
  }
}
interface DownloadShareDashChartFileParams {
  downloadId: string;
  shareToken: string;
  password?: string | null;
  clientId?: string | null;
}
export async function downloadShareDataChartFile(
  params: DownloadShareDashChartFileParams,
) {
  let promiseList = [getSysConfig(), getShareFileInfo(params)];
  Promise.all(promiseList).then(async (value) => {
    const config = value[0] as ConfigInfo[];
    const {name, path} = value[1] as { name: string, path: string };
    // window.location.href = path;
    const blob = await getBlob(path);
    saveAsFile(blob, name);
  });

  // const [data, headers] = (await requestWithHeader({
  //   url: `shares/download`,
  //   method: 'GET',
  //   responseType: 'blob',
  //   params,
  // })) as any;
  // dealFileSave(data, headers);
}

export async function fetchCheckName(url, data: any) {
  return await request2({
    url: `/${url}/check/name`,
    method: 'POST',
    data: data,
  });
}

export async function getFileInfo(id) {
  try {
    const { data } = await request2<{ name: string, path: string }>({
      method: 'GET',
      url: `/download/fileinfo/${id}`,
    });
    return {
      name: data.name,
      path: data.path,
    };
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export async function getShareFileInfo(
  params: DownloadShareDashChartFileParams,
) {
  try {
    const { data } = await request2<{ name: string, path: string }>({
      method: 'GET',
      url: `/shares/fileinfo/${params.downloadId}`,
      params
    });
    return {
      name: data.name,
      path: data.path,
    };
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export async function getSysConfig() {
  try {
    const { data } = await request2<ConfigInfo[]>({
      method: 'GET',
      url: `/sys/config`,
    });
    return data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export function getOssDownloadUrl(
  config: ConfigInfo[],
  name: string,
  path: string,
) {

}

// const OSS = require("ali-oss");

export async function uploadFile(
  file: File,
  filePath: string,
  ossConfig: OssConfigInfo
) {

  const client = new OSS({
    region: "oss-cn-shanghai",
    accessKeyId: ossConfig.accessKeyId,
    accessKeySecret: ossConfig.accessKeySecret,
    bucket: ossConfig.bucketName,
    stsToken: ossConfig.securityToken
  });

  const res = await client.put(filePath, file);
  return res;
}

export async function fetchDataChart(id: string) {
  const response = await request2<ChartDTO>(`/viz/datacharts/${id}`);
  return convertToChartDto(response?.data);
}

export async function fetchDashboardDetail(boardId: string) {
  const { data } = await request2(`/viz/dashboards/${boardId}`);
  return data;
}

export async function fetchChartDataSet(
  requestParams,
  authorizedToken?: ExecuteToken,
) {
  if (authorizedToken) {
    const { data } = await request2<ChartDataSetDTO>({
      method: 'POST',
      url: `shares/execute`,
      params: {
        executeToken: authorizedToken,
      },
      data: requestParams,
    });
    return data;
  }

  const { data } = await request2<ChartDataSetDTO>({
    method: 'POST',
    url: `data-provider/execute`,
    data: requestParams,
  });
  return data;
}

async function getBlob(url) { // url:是文件在oss上的地址
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";// 请求类型是blob类型
    // xhr.crossOrigin = "*"; // 解决跨域问题
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
}

function saveAsFile(blob, filename) {
  const link = document.createElement("a");
  const body = document.querySelector("body");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename; //修改文件名
  link.style.display = "none";
  body?.appendChild(link);
  link.click();
  body?.removeChild(link);
  window.URL.revokeObjectURL(link.href);
}